import * as React from 'react';
import type { States } from '@@services/services';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import { MemberBubble } from '@@community/components/memberbubble';

export interface IMemberBubbleProps extends ItemTemplateProps {
    community?: States.ICommunityState;
    currentuser?: States.IAppUserState;
    i18n?: States.i18nState;
}

export interface IMemberBubbleState {
}

@connectwith((state: States.IAppState) => {
    return {
        community: state.community,
        currentuser: state.user,
        i18n: state.i18n
    };
})
export class MemberBubbleTemplate extends React.PureComponent<IMemberBubbleProps, IMemberBubbleState> {
    render() {
        return <MemberBubble className={this.props.className || ""} member={this.props.data} />;
    }
}
