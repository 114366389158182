import type { Entities } from '@inwink/entities/entities';
import { logging, ILogger } from '@inwink/logging';
import type { States } from '../../../services/services';
import { getDb } from '../../../data';
import { Collection } from '@inwink/inwinkdb';

const logger = logging.getLogger("Data");

export function fromCommunityJSON(eventid: string, jsonDB): States.ICommunityDataStore {
    const data = newCommunityData(eventid);
    if (!jsonDB) {
        initCommunityDataStore(data);
        if ((global as any).inwinkCommunityBootstrap) {
            if ((global as any).inwinkCommunityBootstrap.main) {
                data.db.loadJSONObject((global as any).inwinkCommunityBootstrap.main.data);   
            } else {
                const newCollections : Collection<any>[] = [];
                data.db._collections.forEach((c) => {
                    //inwinkCommunityBootstrap
                    const collectionData = (global as any).inwinkCommunityBootstrap[c.name];
                    if (collectionData) {                
                        newCollections.push(Collection.fromJSONObject(collectionData));                
                    } else {
                        newCollections.push(c);
                    }
                });
                data.db._collections = newCollections;
            }
        }
        
    } else if (typeof jsonDB === "object") {
        data.db.loadJSONObject(jsonDB);    
    } else {
        data.db.loadJSON(jsonDB);
    }
    data.ready = Promise.resolve(data);
    initCommunityDataStore(data);
    return data;
}

export function newCommunityData(communityid: string, options?: any) {
    const db = getDb(communityid, options);

    return <States.ICommunityDataStore>{
        db: db,
        communityId: communityid,
        members: null,
        hasData: false,
        hasFullData: false,
        syncInProgess: false,
        communityDetail: null,
        templates: null,
        fieldtemplates: null,
        lastsyncs: null,
        ready: null,
        savingPromise: Promise.resolve(),
        save() {
            const promise = this.savingPromise.then(() => {
                return db.saveDatabase();
            });

            const safePromise = promise.then(null, () => { });

            this.savingPromise = safePromise;
            const cleanup = () => {
                if (this.savingPromise === safePromise) {
                    this.savingPromise = Promise.resolve();
                }
            };

            safePromise.then(cleanup);

            return promise;
        }
    };
}

function initCommunityDataStore(eData: States.ICommunityDataStore) {
    const communityData = eData;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const getDbCollection = function coll<T extends object>(name, options) {
        let col = communityData.db.getCollection<T>(name);
        if (!col) col = communityData.db.addCollection<T>(name, options);

        return col;
    };

    communityData.communityDetail = getDbCollection<States.ICommunityDetail>('cd', { unique: ['id'] });
    communityData.templates = getDbCollection<Entities.IContentTemplate>('tpl', { unique: ['id'] });
    communityData.fieldtemplates = getDbCollection<Entities.IFieldTemplate>('ftpl', { unique: ['entityName'] });
    communityData.lastsyncs = getDbCollection<Entities.ILastSync>('ls', { unique: ['key'] });

    if (communityData.templates.count() > 0) {
        communityData.hasData = true;
    }
    if (communityData.fieldtemplates.count() > 0) {
        communityData.hasFullData = true;
    }
}

export function getCommunityRepository(
    communityid: string, options?: any, disableRead?: boolean, currentLogger?: ILogger
): States.ICommunityDataStore {
    const communityData = newCommunityData(communityid, options);

    communityData.ready = new Promise<States.ICommunityDataStore>((dbComplete, dbError) => {
        if (disableRead) {
            initCommunityDataStore(communityData);

            dbComplete(communityData);
        } else {
            communityData.db.loadDatabase(null, true).then(() => {
                initCommunityDataStore(communityData);

                dbComplete(communityData);
            }, dbError);
        }
    }).then((data) => {
        communityData.ready = Promise.resolve(data);
        return data;
    }, (err) => {
        (currentLogger || logger).error("error getting community repository", err);
        communityData.ready = Promise.resolve();
        return Promise.reject(err);
    });

    return communityData;
}
