import { States } from "@@services/services";
import { asyncReducers } from "@@services/index";
import { ICommunityRequests } from '@@services/apiaccessprovider.definition';
import { initCommunityBlocs } from '@@community/index';
import { communityReducer } from "../communityservice";
import { communityGroupReducer } from "../communitygroupservice";
import { basketReducer } from "@@services/orderservice";

export { CommunityShell } from '@@modules/community/shell/communityshell';
export { initEventInfoProvider } from '../../../../client/eventinfo';
export { fromCommunityJSON } from '@@community/data/communitydata';

asyncReducers.community = communityReducer;
asyncReducers.communityGroupContext = communityGroupReducer;
asyncReducers.orderBasket = basketReducer;

initCommunityBlocs();

export const initCommunityActions = {
    checkCommunityUser() {
        return (dispatch, getState: () => States.IAppState) => {
            return import("../../module.communityuseractions").then((mod) => {
                return mod.currentUserActions.checkCurrentUser()(dispatch, getState).then(null, (err) => {
                    console.error("error checking community user", err);
                });
            });
        };
    },

    setCurrentCommunity(
        communityRequests: ICommunityRequests,
        community: States.ICommunityDetail,
        data: States.ICommunityDataStore,
        isCommunityRoot: boolean,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        req?
    ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState: () => States.IAppState) => {
            let trackingPromise : Promise<any> = Promise.resolve();

            if (!__SERVERSIDE__) {
                const trackingConfig = community?.configuration?.companion?.tracking;
                const cookiebarConfig = community?.configuration?.companion?.cookiesWarning;
                trackingPromise = import("@@services/tracking/actions").then((mod) => {
                    return mod.trackingServiceActions.init(
                        community.id, community.id, null, trackingConfig, cookiebarConfig
                    )(dispatch, getState);
                });
            }
            dispatch({
                type: "COMMUNITY_SET",
                payload: {
                    requestManagers: communityRequests,
                    community: community,
                    data: data,
                    isCommunityRoot: isCommunityRoot,
                    isReady: true
                }
            });

            return trackingPromise;
        };
    }
};
