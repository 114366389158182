import { Entities } from '@inwink/entities/entities';
import { States } from "@@services/services";
import { getCMSPreview } from '@@services/previewservice';

const INITIAL_STATE: States.ICommunityState = {
    detail: null,
    tinyUrl: null,
    tenantid: null,
    communityid: null,
    data: null,
    customerid: null
};

export function communityReducer(state: States.ICommunityState = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "COMMUNITY_SETID": {
            return {
                ...state,
                communityid: action.payload.communityid,
                tenantid: action.payload.tenantid,
                customerid: action.payload.customerid,
            };
        }
        case "COMMUNITY_SETTEMPLATES": {
            const res = {};
            res[action.payload.application] = action.payload.templates;
            return { ...state, apptemplates: { ...res } };
        }
        case "COMMUNITY_REFRESH": {
            return { ...state };
        }
        case "COMMUNITY_HASCURRENTEVENT": {
            return { ...state, hasCurrentEvent: action.payload };
        }
        case "COMMUNITY_SETPARENTCOMMUNITY": {
            return { ...state, ...action.payload };
        }
        case "COMMUNITY_CLEAR": {
            return {  };
        }
        case "COMMUNITY_SET": {
            if (InWinkPreview) {
                if (typeof window !== "undefined") {
                    getCMSPreview().then((previewservice) => {
                        previewservice.setEvent(action.payload);
                    });
                }
            }

            return {
                ...state,
                requestManagers: action.payload.requestManagers,
                isCommunityRoot: action.payload.isCommunityRoot,
                isReady: (action.payload.isReady) || false,
                detail: action.payload.community,
                tenantid: action.payload.community?.tenantId,
                customerid: action.payload.community?.customerId,
                data: action.payload.data,
                communityid: action.payload.community?.id,
                tinyUrl: action.payload.community?.tinyUrl,
                apptemplates: null
            };
        }

        case "COMMUNITY_ISREADY": {
            return { ...state, isReady: true };
        }

        case "COMMUNITY_SETDATA": {
            return {
                ...state,
                data: action.payload,
                stamp: new Date()
            };
        }

        case "COMMUNITY_DATACHANGED": {
            if (action.payload && action.payload.communityData) {
                return {
                    ...state,
                    data: action.payload.communityData,
                    stamp: new Date()
                };
            }

            return { ...state, stamp: new Date() };
        }

        case "COMMUNITY_SETDETAIL": {
            return { ...state, detail: action.payload };
        }

        case "COMMUNITY_ADDORUPDATETEMPLATE": {
            const newTemplate: Entities.IContentTemplateBase<any> = { ...action.payload };
            const actualTemplate = state.data.templates.data.find((t) => t.application === newTemplate.application
                && t.contentType === newTemplate.contentType
                && t.tinyUrl === newTemplate.tinyUrl);

            if (actualTemplate) {
                state.data.templates.update(newTemplate as any);
            } else {
                state.data.templates.insert(newTemplate);
            }
            state.data.save();
            return state;
        }

        default:
            return state;
    }
}

export const communityActions = {
    communityDataChanged(communityData?: States.ICommunityDataStore) {
        return (dispatch) => {
            dispatch({ type: 'COMMUNITY_DATACHANGED', payload: { communityData } });
        };
    },

    setApplicationTemplates(application: string, templates) {
        return (dispatch) => {
            dispatch({ type: 'COMMUNITY_SETTEMPLATES', payload: { application, templates } });
        };
    },

    setCommunityId(communityid: string, tenantId: string, customerId: string) {
        return (dispatch) => {
            dispatch({
                type: 'COMMUNITY_SETID',
                payload: {
                    communityid,
                    tenantid: tenantId,
                    customerid: customerId
                }
            });
        };
    },

    clearCurrentCommunity() {
        return (dispatch) => {
            dispatch({ type: 'COMMUNITY_SET', payload: { community: null, data: null } });
        };
    },
};
