/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { motion, MotionProps, AnimationProps } from 'framer-motion';
import { getMemberDisplayName, type IMember } from '@@community/data';
import type { States } from '@@services/services';
import { IInwinkStore, wrapReduxStore } from '@@store/index';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import type { Entities } from '@inwink/entities/entities';

import "./memberbubble.less";

const animatePhotoInitial: MotionProps["initial"] = {
    opacity: 0,
};

const animatePhotoAnimate: AnimationProps["animate"] = {
    opacity: 1
};

const animatePhotoTransition: AnimationProps["transition"] = {
    duration: 0.6,
    delay: 0.2
};

export interface IMemberBubbleProps {
    member?: IMember;
    user?: States.IAppUserState;
    className?: string;
    store?: IInwinkStore;
    i18nHelper?: Entities.i18nHelper;
    disableClick?: boolean;
}

@connectwith((state) => { return { user: state.user }; })
@withI18nHelper()
class MemberBubbleComponent extends React.PureComponent<IMemberBubbleProps, any> {
    constructor(props: IMemberBubbleProps) {
        super(props);
        this.state = this.process(props);
    }

    componentDidUpdate(prevprops: IMemberBubbleProps) {
        if (prevprops.member !== this.props.member) {
            this.setState(this.process(this.props));
        }
    }

    process(props: IMemberBubbleProps) {
        if (props.member) {
            let photoUrl = null;
            const tmp = [];
            if (props.member.photo?.url) {
                const url = props.member.photo?.thumbSmallUrl
                    || props.member.photo?.thumbUrl
                    || props.member.photo?.url;
                photoUrl = "url('" + url + "')";
            }

            const fullname = getMemberDisplayName(props.member);
            fullname.split(" ").forEach((s) => {
                if (s) {
                    tmp.push(s[0].toUpperCase());
                }
            });
            const initials = tmp.slice(0, 3).join("");

            return {
                clickable: !props.disableClick && props.user.currentUser?.member?.id !== props.member.id,
                fullname: fullname,
                initials: initials,
                photoUrl: photoUrl
            };
        }
    }

    clicked = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const target = arg.currentTarget;
        import("@@community/features/networking/actions").then((mod) => {
            mod.networkingActions.showMember(this.props.i18nHelper, target, this.props.member.id, null, this.props.member)(
                this.props.store.dispatch,
                this.props.store.getState
            );
        });
    };

    render() {
        let content = null;
        let photo = null;

        if (this.state?.initials) {
            content = <motion.div
                className="initials"
                initial={animatePhotoInitial}
                animate={animatePhotoAnimate}
                transition={animatePhotoTransition}
            >
                {this.state?.initials}
            </motion.div>;
        }
        if (this.state?.photoUrl) {
            photo = <motion.div
                className="photo bloc-defaultbg"
                initial={animatePhotoInitial}
                animate={animatePhotoAnimate}
                transition={animatePhotoTransition}
                style={{ backgroundImage: this.state?.photoUrl}}
            />;
        }

        return <div
            className={
                "member-bubble bloc-lightborder bloc-accentbg "
                    + (this.state?.clickable ? "clickable " : "")
                    + (this.props.className || "")
            }
            onClick={this.state?.clickable ? this.clicked : undefined}
        >
            {content}
            {photo}
        </div>;
    }
}

export const MemberBubble = wrapReduxStore(MemberBubbleComponent);