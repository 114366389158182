import { States } from "./services";

const INITIAL_STATE = {
    productId: null,
    //Quand on vient depuis le bloc product on force la quantité à 1
    quantity: 1
} as States.IAppOrderState;

export const basketReducer = (state = INITIAL_STATE, action = { type: '', payload: null }) => {
    switch (action.type) {
        case "ADD_PRODUCT_BASKET":
            return {
                ...state,
                productId: action.payload.productId
            };
        default:
            return state;
    }
};

export const addProductToBasket = (productId) => {
    return {
        type: "ADD_PRODUCT_BASKET",
        payload: {
            productId: productId
        }
    };
};
